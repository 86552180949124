/*eslint-disabled*/
import { useState } from 'react';
import '../assets/css/footer.css';

import { LoadingOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, notification, Popover, Spin } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.png';

import * as yup from 'yup';

import Apple from '../assets/images/apple_store.png';
import play from '../assets/images/play_store.png';

import axios from '../common/axios';
import routes from './routes';

const Footer = (props) => {

    const content = (
        <div>
            <p>App is currently in development. Please wait</p>
        </div>
    );

    return (
        <div className={`footer ${props.noSpace ? 'noSpace' : ''}`}>
            <div className="second-footer">
                <div className="contain">
                    <div className="grid-footer-4">
                        <div className="first-side">
                            <h6>About Konnect</h6>
                            {/* <img src={Logo} className="w-[30%] mb-8" alt="konnect logo" /> */}
                            <p className='text-white opacity-60 text-2xl leading-loose'>
                                Food? Grocery? Household? Snack, Foodstuffs? We have got it all, if you cannot find what you are looking for, drop us a message and we will add it! Konnect allows you to enjoy instant and monthly cash rewards from product offers and commissions when you refer friends.
                            </p>
                        </div>
                        <div className="first-side">
                            <h6 className="">Who We Are</h6>
                            <ul className="">
                                <li><Link to="/contact">About Us</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                                <li>
                                    <Link to="/contact">Blog</Link>
                                </li>
                                {/* <li><Link to="/joinus">Partner</Link></li>
                                <li><Link to="/cart">Cart</Link></li> */}
                            </ul>
                        </div>
                        <div className="first-side">
                            <h6>More Info</h6>
                            <ul>
                                <li><Link to={routes.profile_orders}>Track your Order</Link></li>
                                <li><Link to="/profile/loyalty">Your Account</Link></li>
                                <li><Link to="/terms">Terms</Link></li>
                                <li><Link to="/privacy">Policies</Link></li>
                            </ul>
                        </div>
                        <div className="second-side">
                            <h6 className="footer_download_button">Connect with us</h6>
                            <div style={{ marginTop: '10%' }}></div>
                            <a href="https://www.facebook.com/profile.php?id=100090695480136" rel="noreferrer" w target="_blank">
                                <i className="uil uil-facebook-f"></i>
                            </a>
                            <a href="https://www.instagram.com/konnectwise9ja/" rel="noreferrer" target="_blank">
                                <i className="uil uil-instagram"></i>
                            </a>
                            <a href="https://twitter.com/konnect_ws" rel="noreferrer" target="_blank">
                                <i className="uil uil-twitter-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="first-footer">
                <div className="contain">
                    <p style={{ color: "white", fontSize: "1.2rem" }}>&bull; All rights reserved &copy; Konnect</p>
                </div>
            </div>
        </div>
    )
}

export default Footer