import '../../assets/css/form.css';

import { ArrowRightOutlined, LoadingOutlined } from "@ant-design/icons";
import { Input, notification, Spin, Divider } from 'antd';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { signIn, _get_profile } from '../../common/axios_services';
import Jwt_encrypt from '../../common/Jwt_encrypt';
import Footer from '../../components/Footer-new';
import Navbar from '../../components/Navbar';



const Signin = () => {
    let params = new URLSearchParams(window?.location?.search);
    let navigate = useNavigate();
    const antIcon = <LoadingOutlined style={{ fontSize: 24, color: '#fff' }} spin />;
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [fetchingData, setFetchingData] = useState(false)
    const [redirectUrl,] = useState(params.get('konnectrd') ? params.get('konnectrd') : '');
    const [redir,] = useState(params.get('redir') ? params.get('redir') : '');
    const [referralCode,] = useState(params.get('konnectrfc') ? params.get('konnectrfc') : '');
    const [useAccountID, setUseAccountID] = useState(false); // Track sign-in method (default to Account ID)







    const { handleSubmit, control } = useForm({
        defaultValue: {
            accountID: "",
            loginPin: "",
            emailAddress: "",
            password: "",
        },
    });

    const { handleSubmit: handleMobileSubmit, control: controlMobileSubmit } = useForm({
        defaultValue: {
            accountID: "",
            loginPin: "",
            emailAddress: "",
            password: "",
        },
    });

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({
            message: title ? title : '',
            description: message ? message : "",
            placement: "bottom-right"
        });
    };



    const submitMe = async e => {
        setErrorMessage('');
        setFetchingData(true);
        // let { emailAddress, password } = e;
        const signInData = useAccountID
            ? { email: e.accountID, password: e.loginPin, app: "web", device_id: "", device_type: "", role_id: 1 }
            : { email: e.emailAddress, password: e.password, app: "web", device_id: "", device_type: "", role_id: 1 };

        try {
            const response = await signIn(signInData)
            localStorage.setItem('konnect_token', Jwt_encrypt({ token: response?.data?.data?.token }))
            openNotificationWithIcon('success', response?.data?.title, response?.data?.message)
            let result = await _get_profile();
            const restaurantStatus = result?.data?.data.is_restaurant;

            if (redir) {
                navigate(`/${redir}`)
            } else if (restaurantStatus) {
                navigate(`/restaurant/dashboard`)
            } else {
                navigate(`/home`)
            }
        } catch (err) {
            setFetchingData(false);
            if (err.response) {
                if (err?.response?.data?.message?.toLowerCase().includes('not been verified')) {
                    navigate('/verify-email')
                }
                openNotificationWithIcon('error', err?.response?.data?.title, err?.response?.data?.message)
            } else {
                openNotificationWithIcon('error', "Something went wrong", "An error occurred while signing user in. Please reload page to try again")
            }

        }
    }



    return (
        <div className="form-pages">
            <div className="mobile">
                <Navbar />
                <div className="container form_design_pages">
                    <div className="">
                        <div className="form auth">
                            <div className="form-text">
                                <h3 className='text-5xl'>Login to your Konnect account!</h3>
                                <p className='text-3xl'>Think Food, think Konnect. Family & friends eat cheaper. Refer friends and earn monthly.</p>
                                {errorMessage ?
                                    <p className="form-error">{errorMessage}</p> : ''
                                }
                                {successMessage ?
                                    <p className="success-message">{successMessage}</p> : ''
                                }
                            </div>
                            <form onSubmit={handleMobileSubmit(submitMe)}>
                                <div className="flex justify-between items-center mb-4">
                                    <button type="button" onClick={() => setUseAccountID(!useAccountID)} className="text-white text-[12px] font-gilroyBold focus:outline-none py-2 px-5 rounded-xl bg-[#1f6b2b] ">
                                        {useAccountID ? 'Sign in using Email Address' : 'Sign in with Account ID'}
                                        <ArrowRightOutlined style={{ marginLeft: "3px" }} />
                                    </button>
                                </div>
                                <div>
                                    {useAccountID ? (
                                        <>
                                            <div className="form_group">
                                                <label htmlFor="accountID" style={{ width: '100%' }}>Account ID</label>
                                                <Controller name="accountID" defaultValue="" control={controlMobileSubmit}
                                                    render={({ field }) => (
                                                        <Input {...field} type="tel" style={{ height: "5rem" }} />
                                                    )} />
                                            </div>
                                            <div className="form_group">
                                                <label htmlFor="loginPin">Login Pin</label>
                                                <Controller name="loginPin" control={controlMobileSubmit}
                                                    render={({ field }) => (
                                                        <Input.Password {...field} defaultValue="" inputMode='numeric' style={{ height: "5rem" }} />
                                                    )} />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="form_group">
                                                <label htmlFor="emailAddress" style={{ width: '100%' }}>Email Address</label>
                                                <Controller name="emailAddress" defaultValue="" control={controlMobileSubmit}
                                                    render={({ field }) => (
                                                        <Input {...field} type="email" style={{ height: "5rem" }} />
                                                    )} />
                                            </div>
                                            <div className="form_group">
                                                <label htmlFor="password">Password</label>
                                                <Controller name="password" defaultValue="" control={controlMobileSubmit}
                                                    render={({ field }) => (
                                                        <Input.Password {...field} type="password" style={{ height: "5rem" }} />
                                                    )} />
                                            </div>
                                        </>
                                    )}
                                </div>

                                {/*  */}
                                {/* <div className="form-group">
                                    <label htmlFor="emailAddress" style={{ width: '100%' }}>Email address</label>
                                    <Controller control={controlMobileSubmit} defaultValue="" name="emailAddress"
                                        render={({ field }) => (
                                            <Input {...field}
                                                type="email" style={{ height: '5rem' }} />
                                        )
                                        } />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <Controller name="password" defaultValue="" control={controlMobileSubmit}
                                        render={
                                            ({ field }) => (
                                                <Input.Password {...field}
                                                    type="password" style={{ height: '5rem' }} />
                                            )
                                        } />
                                </div> */}
                                <div>
                                    <Link style={{ display: 'block', marginBottom: 5 }} to={`/signup/?konnectrfc=${referralCode}&konnectrd=${redirectUrl}`}> New customer? <span>Create your account</span></Link>
                                    {/* <Link style={{ display: 'block', marginBottom: 5 }} to={`/verify-email`}>Confirm Email? <span>Use Passcode</span></Link> */}
                                    <Link style={{ display: 'block' }} to={`/resetsendmail`}>Lost Password? <span>Recover password</span></Link>
                                </div>
                                {
                                    !fetchingData ?
                                        <button className="bg_green" style={{
                                            height: '4.5rem', width: '100%',
                                            borderRadius: '3px', marginTop: '10%'
                                        }}>
                                            Sign in</button>
                                        :
                                        <button className="bg_green" disabled style={{
                                            height: '4.5rem', width: '100%',
                                            borderRadius: '3px', marginTop: '10%'
                                        }}>
                                            <Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                }
                                <div>
                                </div>
                            </form>
                            <div style={{ marginBottom: '20px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="desktop show-all">
                <Navbar />
                <div className="bg-[#F3F4F6] h-[90vh] flex items-center justify-center">
                    <div className="bg-white shadow-xl pt-5 px-5 pb-5 md:w-[35%] md:max-w-[35%] overflow-hidden">
                        <div className="form-text mb-3">
                            <h3 className="text-4xl mb-14 text-cente font-bold">Login to your Konnect account!</h3>
                            {/* <p className="text-xl md:leading-relaxed leading-relaxed">Think Food, think Konnect. Family & friends eat cheaper. Refer friends and earn monthly.</p> */}
                            {errorMessage ?
                                <p className="form-error">{errorMessage}</p> : ''
                            }
                            {successMessage ?
                                <p className="success-message">{successMessage}</p> : ''
                            }
                        </div>
                        <form onSubmit={handleSubmit(submitMe)}>
                            <div>
                                {useAccountID ? (
                                    <>
                                        <div className="form-group mb-8">
                                            <label className="text-[14px]" htmlFor="accountID" style={{ width: '100%' }}>Account ID</label>
                                            <Controller name="accountID" defaultValue="" control={control}
                                                render={({ field }) => (
                                                    <Input {...field} type="text" style={{ height: "5.5rem" }} />
                                                )} />
                                        </div>
                                        <div className="form-group">
                                            <label className="text-[14px]" htmlFor="loginPin">Login Pin</label>
                                            <Controller name="loginPin" control={control}
                                                render={({ field }) => (
                                                    <Input.Password {...field} defaultValue="" type="text" style={{ height: "5.5rem" }} />
                                                )} />
                                        </div>
                                    </>
                                ) :
                                    (
                                        <>
                                            <div className="form-group mb-8">
                                                <label htmlFor="emailAddress" className="text-[14px]" style={{ width: '100%' }}>Email Address</label>
                                                <Controller name="emailAddress" defaultValue="" control={control}
                                                    render={({ field }) => (
                                                        <Input {...field} type="email" style={{ height: "5.5rem" }} />
                                                    )} />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="password" className="text-[14px]">Password</label>
                                                <Controller name="password" defaultValue="" control={control}
                                                    render={({ field }) => (
                                                        <Input.Password {...field} type="password" style={{ height: "5.5rem" }} />
                                                    )} />
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            <div className="flex justify-between">
                                <div></div>
                                {
                                    useAccountID ? <Link style={{ fontSize: '1.4rem', display: 'block' }} to={`/resetsendmail`}>Forgot Pin? <span>Recover pin</span></Link>
                                        : <Link style={{ fontSize: '1.4rem', display: 'block' }} to={`/resetsendmail`}>Lost Password? <span>Recover password</span></Link>
                                }
                            </div>
                            <div style={{ marginTop: '1rem' }}></div>
                            <div>
                                {
                                    !fetchingData ?
                                        <button className="bg_green mb-3" style={{
                                            height: '5.5rem', width: '100%',
                                            borderRadius: '3px'
                                        }}>
                                            Sign In</button>
                                        :
                                        <button className="bg_green mb-3" disabled style={{
                                            height: '5.5rem', width: '100%',
                                            borderRadius: '3px'
                                        }}>
                                            <Spin style={{ marginLeft: '10px' }} indicator={antIcon} /></button>
                                }
                            </div>
                            <div>
                                {
                                    !useAccountID ?
                                        <button onClick={() => setUseAccountID(!useAccountID)} type="button"
                                            className="border-2 border-solid text-main font-medium border-main text-2xl" style={{
                                                height: '5.5rem', width: '100%',
                                                borderRadius: '3px'
                                            }}>
                                            Sign In With Wallet ID</button>
                                        :
                                        <button onClick={() => setUseAccountID(!useAccountID)} type="button"
                                            className="border-2 border-solid text-main font-medium border-main text-2xl" style={{
                                                height: '5.5rem', width: '100%',
                                                borderRadius: '3px'
                                            }}>
                                            Sign In With Email Address</button>
                                }
                            </div>
                        </form>
                        <div style={{ marginBottom: '15px' }}></div>
                        <div className="">
                            <Link style={{ fontSize: '1.4rem', display: 'block' }} to={`/signup/?konnectrfc=${referralCode}&konnectrd=${redirectUrl}`}>New customer? <span>Create your account</span></Link>
                            {/* <Link style={{ fontSize: '1.3rem', display: 'block', marginBottom: 5 }} to={`/verify-email`}>Confirm Email? <span>Use Passcode</span></Link> */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Signin