/*eslint-disabled*/
import { useState } from 'react';
import '../assets/css/footer.css';

import { LoadingOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input, notification, Popover, Spin } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/logo.png';

import * as yup from 'yup';

import Apple from '../assets/images/apple_store.png';
import play from '../assets/images/play_store.png';

import axios from '../common/axios';
import routes from './routes';
import { TiSocialFacebookCircular } from "react-icons/ti";
import { BsAndroid2, BsApple, BsTwitterX, BsInstagram } from "react-icons/bs";



const Footer = (props) => {

    const content = (
        <div>
            <p>App is currently in development. Please wait</p>
        </div>
    );

    return (
        <div className={`footer ${props.noSpace ? 'noSpace' : ''}`}>
            <div className="second-footer">
                <div className="contai px-32 py-32">
                    <div className="grid-footer-4 gap-28">
                        <div className="first-side">
                            {/* <h6>About Konnect</h6> */}
                            <img src={Logo} className="w-[30%] mb-8" alt="konnect logo" />
                            <p className='text-white opacity-80 text-2xl leading-loose'>
                                Food? Grocery? Household? Snack, Foodstuffs? We have got it all, if you cannot find what you are looking for, drop us a message and we will add it! Konnect allows you to enjoy instant and monthly cash rewards from product offers and commissions when you refer friends.
                            </p>
                        </div>
                        <div className="first-side">
                            <div className="pb-10 pt-3">
                                <h6 className="text-3xl text-white">Who We Are</h6>
                            </div>
                            <ul className="flex flex-col gap-5">
                                <li><Link className="text-2xl text-white opacity-80" to="/contact">About Us</Link></li>
                                <li><Link className="text-2xl text-white opacity-80" to="/contact">Contact Us</Link></li>
                                <li>
                                    <Link className="text-2xl text-white opacity-80" to="/contact">Blog</Link>
                                </li>
                                {/* <li><Link to="/joinus">Partner</Link></li>
                                <li><Link to="/cart">Cart</Link></li> */}
                            </ul>
                        </div>
                        <div className="first-side">
                            <div className="pb-10 pt-3">
                                <h6 className="text-3xl text-white">More Info</h6>
                            </div>
                            <ul className="flex flex-col gap-5">
                                <li><Link className="text-2xl text-white opacity-80" to={routes.profile_orders}>Track your Order</Link></li>
                                <li><Link className="text-2xl text-white opacity-80" to="/profile/loyalty">Your Account</Link></li>
                                <li><Link className="text-2xl text-white opacity-80" to="/terms">Terms</Link></li>
                                <li><Link className="text-2xl text-white opacity-80" to="/privacy">Policies</Link></li>
                            </ul>
                        </div>
                        <div className="second-side">
                            <div className="pb-10 pt-3">
                                <h6 className="text-3xl text-white">Connect with us</h6>
                            </div>
                            <div className="flex gap-10 items-center">
                                <a href="https://www.facebook.com/profile.php?id=100090695480136" rel="noreferrer" w target="_blank">
                                    <TiSocialFacebookCircular className='text-5xl text-white opacity-80' />
                                </a>
                                <a href="https://www.instagram.com/konnectwise9ja/" rel="noreferrer" target="_blank">
                                    <BsInstagram className='text-4xl text-white opacity-80' />
                                </a>
                                <a href="https://twitter.com/konnect_ws" rel="noreferrer" target="_blank">
                                    <BsTwitterX className='text-4xl text-white opacity-80' />
                                </a>
                            </div>
                            <a href="https://twitter.com/konnect_ws" rel="noreferrer" className="text-2xl text-white opacity-80 mt-8 block" target="_blank">
                                eadelekeife@gmail.com
                            </a>
                            <div className="mt-5 flex items-center gap-5">
                                <div className="rounded-xl px-6 py-3 flex items-center gap-3 w-max bg-white opacity-80">
                                    <BsAndroid2 className="text-3xl" />
                                    <h5 className="text-2xl">Android</h5>
                                </div>
                                <div className="rounded-xl px-6 py-3 flex items-center gap-3 w-max bg-white opacity-80">
                                    <BsApple className="text-3xl" />
                                    <h5 className="text-2xl">iPhone</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="first-footer">
                <div className="px-32 py-16">
                    <p className="text-2xl text-white opacity-80">&bull; All rights reserved &copy; Konnect</p>
                </div>
            </div>
        </div>
    )
}

export default Footer